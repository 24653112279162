.contentEditableLight {
  padding: 0px 8px;
  border-radius: 8px;
  overflow-y: scroll;
}

.contentEditableDark {
  padding: 0px 8px;
  border-radius: 8px;
  color: white;
  overflow-y: scroll;
}

.contentEditableLight:focus {
  outline: none;
}
.contentEditableDark:focus {
  outline: none;
}
.placeholder {
  position: fixed;
  padding-left: 8px;
  margin-top: 56px;
  color: gray;
  pointer-events: none;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}
.highlight {
  background-color: yellow;
}

.al-l {
  text-align: left;
}

.al-c {
  text-align: center;
}

.al-r {
  text-align: right;
}

.al-j {
  text-align: justify;
}

.ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
}

.ol {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
}

.il {
  margin: 0 0 0 1em;
  padding: 0.5em 0;
}
