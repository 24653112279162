body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:where(.css-dev-only-do-not-override-otvge).ant-switch.ant-switch-checked {
  background: #2793f2 !important;
}
:where(.css-dev-only-do-not-override-otvge).ant-switch {
  background: #6240dc !important;
}
.menu-transition {
  transition: width 0.3s ease-in-out;
}
.slide-in {
  opacity: 0;
  transform: translateX(-5px); /* Décalage initial depuis la gauche */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Ajustez la durée de l'animation selon vos besoins */
}

.slide-in.active {
  opacity: 1;
  transform: translateX(0); /* Ajustez le décalage final */
}
